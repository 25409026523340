<template>
  <div id="app">
    <headerBox/>
    <!-- <navsBox/> -->
    <div class="mtop"></div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <Footer/>
  </div>
</template>

<script>
import headerBox from '@/components/common/headerBox.vue'
// import navsBox from '@/components/common/navBox.vue'
import Footer from '@/components/common/Footer.vue'

export default {
  name: 'app',
  components: {
    headerBox,
    Footer
  }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/common/index.less");
.fade{
  &-enter-active, &-leave-active {
    transition: all .3s
  }
  &-enter, &-leave-active {
    opacity: 0
  }
}
</style>
