import Vue from 'vue'
import VueRouter from 'vue-router'
const HomeView = () => import('../page/Home.vue')
const About = () => import(/* webpackChunkName: "home" */ '../page/About.vue')
const Company = () => import(/* webpackChunkName: "company" */ '../components/about/Company.vue')
const Task = () => import(/* webpackChunkName: "task" */ '../components/about/Task.vue')
const Research = () => import(/* webpackChunkName: "research" */ '../page/Research.vue')
const Team = () => import(/* webpackChunkName: "team" */ '../page/Team.vue')
const Technology = () => import(/* webpackChunkName: "technology" */ '../page/Technology.vue')
const TechnologyView = () => import(/* webpackChunkName: "technologyView" */ '../page/TechnologyView.vue')
const Pilot = () => import(/* webpackChunkName: "pilot" */ '../page/Pilot.vue')
const News = () => import(/* webpackChunkName: "group-Team" */ '../page/News.vue')
const centerNews = () => import(/* webpackChunkName: "centerNews" */ '../components/news/centerNews.vue')
const newsView = () => import(/* webpackChunkName: "newsView" */ '../components/news/newsView.vue')
const communication = () => import(/* webpackChunkName: "communication" */ '../components/news/communicate.vue')
const activity = () => import(/* webpackChunkName: "activity" */ '../components/news/activity.vue')
const consulting = () => import(/* webpackChunkName: "consulting" */ '../components/news/consulting.vue')
const Achievements = () => import(/* webpackChunkName: "achievements" */ '../page/Achievements.vue')
const maintenance = () => import(/* webpackChunkName: "maintenance" */ '../components/achievement/maintenance.vue')
const innovate = () => import(/* webpackChunkName: "innovate" */ '../components/achievement/innovate.vue')
const fund = () => import(/* webpackChunkName: "fund" */ '../components/achievement/fund.vue')
const alliance = () => import(/* webpackChunkName: "alliance" */ '../components/achievement/alliance.vue')

const layout = () => import(/* webpackChunkName: "layout" */ '../page/Layout.vue')
const corporate = () => import(/* webpackChunkName: "corporate" */ '../components/layout/Corporate.vue')
const achieneblist = () => import(/* webpackChunkName: "achieneblist" */ '../components/achievement/Corporate.vue')

const building = () => import(/* webpackChunkName: "building" */ '../page/Building.vue')
const Party = () => import(/* webpackChunkName: "party" */ '../components/building/Party.vue')
const Special = () => import(/* webpackChunkName: "special" */ '../components/building/Special.vue')
const buildView = () => import(/* webpackChunkName: "buildView" */ '../components/building/buildView.vue')
const Contact = () => import(/* webpackChunkName: "Contact" */ '../page/Contact.vue')
const Search = () => import(/* webpackChunkName: "search" */ '../components/common/Search.vue')

const joins = () => import(/* webpackChunkName: "joins" */ '../components/join/communicate.vue')
const joins1 = () => import(/* webpackChunkName: "joins" */ '../components/join/communicate1.vue')
const joinView = () => import(/* webpackChunkName: "joinView" */ '../components/join/joinView.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/jzabout',
    name: 'about',
    component: About,
    meta: {
      requiresAuth: 1
    }
  },
  {
    path: '/company',
    name: 'about',
    component: Company,
    meta: {
      requiresAuth: 1
    }
  },
  {
    path: '/task',
    name: 'about',
    component: Task,
    meta: {
      requiresAuth: 1
    }
  },
  {
    path: '/jzchuangxinyanfa',
    name: 'jzchuangxinyanfa',
    redirect: '/jzyanjiuzhongxin'
  },
  {
    path: '/jzyanjiuzhongxin',
    name: 'research',
    component: Research
  },
  {
    path: '/jzrencaituandui',
    name: 'research',
    component: Team,
    meta: {
      activeName: '/jzchuangxinyanfa'
    }
  },
  {
    path: '/jzjishupingtai',
    name: 'research',
    component: Technology,
    meta: {
      activeName: '/jzchuangxinyanfa'
    }
  },
  {
    path: '/jzjishupingtai/tview',
    name: 'research',
    component: TechnologyView,
    meta: {
      activeName: '/jzchuangxinyanfa'
    }
  },
  {
    path: '/jzzhongshiyanfa',
    name: 'research',
    component: Pilot
  },
  {
    path: '/jzxinwendongtai',
    name: 'news',
    redirect: '/jzzhongxinxinwen'
  },
  {
    path: '/jzzhongxinxinwen',
    name: 'news',
    component: centerNews
  },
  {
    path: '/gongshigonggao',
    name: 'news',
    component: communication
  },
  {
    path: '/jzhuiyihuodong',
    name: 'news',
    component: activity
  },
  {
    path: '/jzchanyezixun',
    name: 'news',
    component: consulting
  },
  {
    path: '/newsview',
    name: 'newsView',
    component: newsView
  },
  {
    path: '/jzchengguozhuanhua',
    name: 'achievements',
    component: Achievements
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: maintenance
  },
  {
    path: '/innovate',
    name: 'innovate',
    component: innovate
  },
  {
    path: '/fund',
    name: 'fund',
    component: fund
  },
  {
    path: '/alliance',
    name: 'alliance',
    component: alliance
  },
  {
    path: '/jzchanyebuju',
    name: 'layout',
    component: layout
  },
  {
    path: '/corporate',
    name: 'corporate',
    component: corporate
  },
  {
    path: '/achieneblist',
    name: 'achieneblist',
    component: achieneblist
  },
  {
    path: '/jzqiyedangjian',
    name: 'building',
    component: building,
    redirect: '/jzqiyedangjian/jzdangjiangongzuo',
    children: [
      {
        path: '/jzqiyedangjian/jzdangjiangongzuo',
        name: 'party',
        component: Party
      },
      {
        path: '/jzqiyedangjian/jzzhuantixuanchuan',
        name: 'special',
        component: Special
      }
    ]
  },
  {
    path: '/buildview',
    name: 'buildView',
    component: buildView
  },
  {
    path: '/jzlianxiwomen',
    name: 'contact',
    component: Contact
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/join',
    name: 'join',
    redirect: '/jzzhuantixuanchuan'
  },
  {
    path: '/jzdangjiangongzuo',
    name: 'join',
    component: joins
  },
  {
    path: '/jzzhuantixuanchuan',
    name: 'join1',
    component: joins1
  },
  {
    path: '/joinview',
    name: 'joinView',
    component: joinView
  }
]
const router = new VueRouter({
  mode: 'history',
  routes,
  base:'/'
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})
export default router
