<template>
  <div class="footer-home inner">
    <div class="footer-tops">
      <div class="shares">
        <a class="iconfont" href="#">&#xe601;</a>
        <a class="iconfont" href="#">&#xe62d;</a>
        <a class="iconfont" href="#">&#xe600;</a>
      </div>
      <div class="footer-logo">
        <img src="../../assets/images/logo.svg" alt=""/>
      </div>
      <ul class="footer-navs">
        <li><router-link to="/jzabout"><i class="iconfont">&#xe63c;</i>关于我们</router-link></li>
        <li><router-link to="/jzchuangxinyanfa"><i class="iconfont">&#xe63c;</i>创新研发</router-link></li>
        <li><router-link to="/achieneblist?layIndex=0"><i class="iconfont">&#xe63c;</i>成果转化</router-link></li>
        <li><router-link to="/jzchanyebuju"><i class="iconfont">&#xe63c;</i>产业布局</router-link></li>
        <li><a href="#"><i class="iconfont">&#xe63c;</i>企业邮箱</a></li>
        <li><a href="#"><i class="iconfont">&#xe63c;</i>常用入口</a></li>
        <li><a href="#"><i class="iconfont">&#xe63c;</i>友情链接</a></li>
      </ul>
    </div>
    <div class="footer-bottom" v-if="commonData.length > 0">
      <div class="footer-info">
        <p>咨询热线：{{commonData[3].value}}&nbsp;&nbsp;&nbsp;&nbsp;联系邮箱：{{commonData[1].value}}</p>
        <p>公司地址：{{commonData[0].value}}</p>
        <p class="footer-copy"><a href="#">版权所有 ©2022</a><a href="#">{{commonData[2].value}}</a><a target="_blank" href="http://beian.miit.gov.cn">{{commonData[4].value}}</a><a class="footer-hide" href=" " target="_blank" >网站建设 ：</a><a class="footer-hide" href="https://28jw.cn/" target="_blank">今网科技</a></p>
      </div>
      <div class="backtop" @click="scrollTop">
        <b class="iconfont">&#xe96f;</b>
        <span>返回顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      commonData: []
    }
  },
  methods: {
    scrollTop () {
      // eslint-disable-next-line no-var
      var timer = setInterval(function () {
        const osTop =
        document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    },
    async getData () {
      const { data: res } = await this.$http.get('/fdata/set?var=icpno_jz,companyname_jz,companyemail_jz,companytel_jz,companyaddress_jz')
      if (res.code === 1) {
        this.commonData = res.data
        console.log(res)
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url("@/assets/css/home/index.less");
</style>
