<template>
  <header class="header-box">
    <!-- <div class="header-wrap inner">
      <h1 class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt=""/>
        </router-link>
      </h1>
      <div class="header-name">
        <img src="../../assets/images/hname.png" alt=""/>
      </div>
      <div class="header-right">
        <div class="header-enter">
          <div class="enter-item">
            <a :href="`mailto:${email}`">
              <i class="iconfont">&#xe631;</i>
              <span>企业邮箱</span>
            </a>
          </div>
          <div class="enter-item">
            <a href="#">
              <i class="iconfont">&#xe63b;</i>
              <span>常用入口</span>
            </a>
          </div>
        </div>
        <div class="search">
          <span class="iconfont" @click="jump()">&#xe632;</span>
          <input type="text" v-model.trim="q" @keyup.enter="jump" placeholder="请输入关键字检索" class="text"/>
        </div>
      </div>
      <div class="menubtn" :class="{'active': headerStatus}" @click="menuSwiper">
        <span></span>
      </div>
    </div> -->
    <div class="header-top">
      <div class="header-top-wr">
        <div class="header-top-item">
          <a :href="`mailto:${email}`" target="_block"><i class="iconfont">&#xe631;</i>企业邮箱</a>
        </div>
        <div class="header-top-item">
          <a href="#"><i class="iconfont">&#xe63b;</i>常用入口</a>
        </div>
        <div class="search-fixs">
          <div class="search-icon iconfont">&#xe632;</div>
          <div class="search">
            <span class="iconfont" @click="jump()">&#xe632;</span>
            <input type="text" v-model.trim="q" @keyup.enter="jump" placeholder="请输入关键字检索" class="text"/>
          </div>
        </div>
      </div>
    </div>
    <div class="header-navs">
      <div class="header-logo">
        <router-link to="/"><img src="@/assets/images/logo2.png" alt=""/></router-link>
      </div>
      <ul class="navs-list" v-if="navsMenus.length > 0">
        <li><router-link to="/home">首页</router-link></li>
        <li>
          <router-link :class="{'router-link-active': active_menu==='/company' || active_menu==='/task'}" :to="navsMenus[0].biaoshi">{{navsMenus[0].name}}</router-link>
          <div class="navs-menu" >
            <ul class="navs-menu-li">
              <li v-for="(item, index) in navsMenus[0].child" :key="index">
                <router-link :to="`${navsMenus[0].biaoshi}?id=${item.biaoshi.slice(1)}`">{{item.name}}</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link :class="{'router-link-active': active_menu==='/jzzhongxinxinwen' || active_menu==='/jzhezuojiaoliu'|| active_menu==='/jzhuiyihuodong' || active_menu==='/jzchanyezixun'}" :to="navsMenus[1].biaoshi">{{navsMenus[1].name}}</router-link>
          <div class="navs-menu" >
            <ul class="navs-menu-li">
              <li v-for="(item1, index1) in navsMenus[1].child" :key="index1">
                <router-link :to="`${item1.biaoshi}`">{{item1.name}}</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link :class="{'router-link-active': active_menu==='/jzyanjiuzhongxin' || active_menu==='/jzrencaituandui'|| active_menu==='/jzjishupingtai' || active_menu==='/jzjishupingtai/tview'}" :to="navsMenus[2].biaoshi">{{navsMenus[2].name}}</router-link>
          <div class="navs-menu" >
            <ul class="navs-menu-li">
              <li v-for="(item2, index2) in navsMenus[2].child" :key="index2">
                <router-link :to="`${item2.biaoshi}`">{{item2.name}}</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link to="/achieneblist?layIndex=0">{{navsMenus[3].name}}</router-link>
          <div class="navs-menu navs-menu1" >
            <ul class="navs-menu-li">
              <li>
                <router-link to="/achieneblist?layIndex=0">知识产权运营中心</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=1">产业创新基金</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=2">产业创新联合体</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=3">产业创新联盟</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link :class="{'router-link-active': active_menu==='/corporate'}" :to="navsMenus[4].biaoshi">{{navsMenus[4].name}}</router-link>
          <div class="navs-menu navs-menu2" >
            <ul class="navs-menu-li">
              <li v-for="(item4, index4) in navsMenus[4].child" :key="index4">
                <router-link :to="`/corporate?layIndex=${index4}`">{{item4.name}}</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link :class="{'router-link-active': active_menu==='/jzdangjiangongzuo' || active_menu==='/jzzhuantixuanchuan'}" to="join">{{navsMenus[5].name}}</router-link>
          <!-- <div class="navs-menu" >
            <ul class="navs-menu-li">
              <li v-for="(item5, index5) in navsMenus[5].child" :key="index5">
                <router-link :to="`${item5.biaoshi}`">{{item5.name}}</router-link>
              </li>
            </ul>
          </div> -->
        </li>
        <!-- <li>
          <router-link :to="navsMenus[6].biaoshi">{{navsMenus[6].name}}</router-link>
        </li> -->
      </ul>
       <div class="menubtn" :class="{'active': headerStatus}" @click="menuSwiper">
        <span></span>
      </div>
    </div>
    <div class="mobile-navs" :class="{'show': headerStatus}">
      <div class="mobile-menus">
         <div class="mobile-item">
          <router-link to="/">首页</router-link>
        </div>
        <el-collapse accordion v-if="navsMenus.length > 0">
          <el-collapse-item :title="navsMenus[0].name" name="2">
           <ol class="navs-menu-leval">
              <li v-for="(item, index) in navsMenus[0].child" :key="index">
                <router-link :to="`${navsMenus[0].biaoshi}?id=${item.biaoshi.slice(1)}`">{{item.name}}</router-link>
              </li>
            </ol>
          </el-collapse-item>
          <el-collapse-item :title="navsMenus[1].name" name="3">
            <ol class="navs-menu-leval">
              <li v-for="(item1, index1) in navsMenus[1].child" :key="index1">
                <router-link :to="`${item1.biaoshi}`">{{item1.name}}</router-link>
              </li>
            </ol>
          </el-collapse-item>
          <el-collapse-item :title="navsMenus[2].name" name="4">
            <ol class="navs-menu-leval">
              <li v-for="(item2, index2) in navsMenus[2].child" :key="index2">
                <router-link :to="`${item2.biaoshi}`">{{item2.name}}</router-link>
              </li>
            </ol>
          </el-collapse-item>
          <el-collapse-item :title="navsMenus[3].name" name="5">
            <ol class="navs-menu-leval">
              <li>
                <router-link to="/achieneblist?layIndex=0">知识产权运营中心</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=1">产业创新基金</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=2">产业创新联合体</router-link>
              </li>
              <li>
                <router-link to="/achieneblist?layIndex=3">产业创新联盟</router-link>
              </li>
            </ol>
          </el-collapse-item>
          <el-collapse-item :title="navsMenus[4].name" name="6">
            <ol class="navs-menu-leval">
              <li v-for="(item4, index4) in navsMenus[4].child" :key="index4">
                <router-link :to="`/corporate?layIndex=${index4}`">{{item4.name}}</router-link>
              </li>
            </ol>
          </el-collapse-item>
          <!-- <el-collapse-item :title="navsMenus[5].name" name="7">
            <ol class="navs-menu-leval">
              <li v-for="(item5, index5) in navsMenus[5].child" :key="index5">
                <router-link :to="item5.biaoshi">{{item5.name}}</router-link>
              </li>
            </ol>
           </el-collapse-item> -->
           <div class="mobile-item">
            <router-link :to="navsMenus[5].biaoshi">{{navsMenus[5].name}}</router-link>
          </div>
        </el-collapse>
      </div>
      <div class="mobile-fix">
        <div class="search">
          <span class="iconfont" @click="jump">&#xe632;</span>
          <input type="text" v-model.trim="q" @keyup.enter="jump" placeholder="请输入关键字检索" class="text"/>
        </div>
        <div class="header-enter">
          <div class="enter-item">
            <a target="_block" :href="`mailto:${email}`">
              <i class="iconfont">&#xe631;</i>
              <span>企业邮箱</span>
            </a>
          </div>
          <div class="enter-item">
            <a href="#">
              <i class="iconfont">&#xe63b;</i>
              <span>常用入口</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data () {
    return {
      headerStatus: false,
      q: '',
      navsMenus: [],
      email: []
    }
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/fdata/category?catid=83,92,97,103,104,107,110')
      const { data: res1 } = await this.$http.get('/fdata/set?var=companyemail_jz')
      if (res.code === 1) {
        this.navsMenus = res.data
        console.log(this.navsMenus)
        this.email = res1.data[0].value
      }
    },
    menuSwiper () {
      this.headerStatus = !this.headerStatus
    },
    jump () {
      if (this.q === '') {
        this.$message.error('请输入关键字检索')
        return false
      }
      this.$router.replace({ path: '/search', query: { q: this.q } })
      this.headerStatus = false
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    active_menu () {
      return this.$route.path
    }
  },
  watch: {
    $route(to, from) {
      this.headerStatus = false
    }
  }
}
</script>
<style lang="less" scoped>
@import url("@/assets/css/common/index.less");
</style>
