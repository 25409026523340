import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './plugins/element.js'
import axios from 'axios'
import { WOW } from 'wowjs'
import filters from './filters/index'
import BaiduMap from 'vue-baidu-map'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import MetaInfo from 'vue-meta-info'

// 全局公共样式
import './assets/css/global.css'
import './assets/css/animate.min.css'
import 'swiper/dist/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css'
// import animated from './assets/css/animate.min.css'

Vue.use(VueAwesomeSwiper)
Vue.use(VueAnimateOnScroll)
Vue.use(MetaInfo)
// Vue.use(animated)
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$filter = filters
Vue.use(BaiduMap, {ak:'7d3a4c6e5e8a70dd200a2eeacf8c1b50'})
axios.defaults.baseURL = 'https://hx.cdwccr.com'

Object.keys(filters).map(v => {
  Vue.filter(v, filters[v])
})

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // 需要执行动画的元素的 class
  animateClass: 'animated', // animation.css 动画的 class
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: true, // 是否在移动设备上执行动画
  live: true
})

new Vue({
  router,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
